html, body {
  font-family: "Montserrat", Verdana, sans-serif;
  font-size: 16px;
  color: #4a4a4a;
}

h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 1.4;
  color: #435172;
  margin: 0.5em 0 1em 0;
}

h2 {
  font-size: 22px;
  font-weight: 600;
  line-height: 1.36;
  color: #435172;
  margin: 0.5em 0 1em 0;
}

h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: #435172;
  margin: 0.5em 0 1em 0;
}

h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  color: #435172;
}

p {
  margin-bottom: 1em;
}

strong {
  font-weight: bold;
}

a, a:visited {
  color: #4a4a4a;
}

.large-number {
  font-size: 80px;
  font-weight: bold;
  line-height: 1.4;
  color: #a2a8b7;
  width: 2em;
}
